import React, { useState, useEffect, useRef } from "react";
import { fetchdashboardlogo, AppIcon } from "./apiFolder/index";
import { useLocation } from "react-router-dom";

const About = () => {
  const [logo, setLogo] = useState([]);
  const [icon, seticon] = useState([]);
  const { 0: school_id } = useLocation().pathname.split("/").slice(-1);
  useEffect(() => {
    // Fetch Logo
    fetchdashboardlogo(school_id)
      .then((res) => {
        console.log("logo00", res.data.body);
        setLogo(res.data.body);
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch Logo
    AppIcon(school_id)
      .then((res) => {
        console.log("ICON", res.data.body);
        seticon(res.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    // Navbar

    <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Navbar
       */}
      <div className="flex">
        <div className="w-1/2 p-4">
          <div className="text-center">
            <a href={`/home/${school_id}`}>
              <img
                src={logo.App_Logo__c}
                alt=""
                loading="lazy"
                className="w-20 mx-auto homelogo"
              />
            </a>
          </div>
          <div className="w-3/6">
            {icon.length > 0 && (
              <p className="text-center iconcss">{icon[0].App_Icon_Name__c}</p>
            )}
          </div>
        </div>
        <div className="w-1/2 p-4 bg-classic homelist">
          <nav className="flex items-center justify-center">
            <ul className="flex space-x-4">
              <li>
                <a href={`/home/${school_id}`} className="elementor-item">
                  Home
                </a>
              </li>
              <li>
                <a
                  href={`/About/${school_id}`}
                  className="elementor-item elementor-item-active"
                >
                  About Us
                </a>
              </li>
              {/* <li>
              <a href="" className="elementor-item">
                Blog
              </a>
            </li> */}
              <li>
                <a href={`/contact-us/${school_id}`} className="elementor-item">
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href={`/privacy-policy/${school_id}`}
                  className="elementor-item "
                >
                  Privacy Policy
                </a>
              </li>
              {/* <li>
              <a
                href=""
                className="elementor-item elementor-download"
              >
                Download Now
              </a>
            </li> */}
            </ul>
          </nav>
        </div>
      </div>
      {/* Navbar end */}
      <div
        className="imagerow1 bg-cover"
        style={{
          background: `url('https://gradedplus.app/wp-content/uploads/2023/07/banner-1.jpg')`,
          backgroundSize: "100% auto",
        }}
      >
        <div className="flex items-center justify-center">
          <img
            src="https://gradedplus.app/wp-content/uploads/2023/07/Group-131054.svg"
            className="img1set"
            alt="Image 1"
          />
        </div>
      </div>
      <div className="pararow flex items-center justify-center">
        <div className="paradiv bg-cover p-6">
          <p className="parasolved text-gray-600 text-center font-serif text-lg">
            The SOLVED founders, executive and supporting teams are made up of
            educators. Unlike most Silicon Valley tech companies, we are
            committed to building products that elevate your school rather than
            our brand.
          </p>
        </div>
      </div>
      <div className="solvedrow grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        <div className="columnthree text-center">
          <img
            src="https://gradedplus.app/wp-content/uploads/2023/07/11zon_cropped-23.png"
            className="solvedimg w-80 ml-10"
            alt="Image 2"
          />
          <div className="imgtext">
            <p className="solvedtext1 mt-4 text-gray-600 font-semibold text-xl">
              JULIO PENAGOS
            </p>
            <div className="">
              <p className="solvedtext text-gray-600 font-semibold text-sm">
                Founding Member & Director of Professional Learning
              </p>
            </div>
          </div>
        </div>
        <div className="columnthree text-center">
          <img
            src="https://gradedplus.app/wp-content/uploads/2023/07/11zon_cropped-24.png"
            className="solvedimg w-80 ml-10"
            alt="Image 2"
          />
          <div className="imgtext">
            <p className="solvedtext1 mt-4 text-gray-600 font-semibold text-xl">
              BIANCA TEJADA
            </p>
            <div className="">
              <p className="solvedtext text-gray-600 font-semibold text-sm">
                Founding Member & Senior ELA Consultant
              </p>
            </div>
          </div>
        </div>
        <div className="columnthree text-center">
          <img
            src="https://gradedplus.app/wp-content/uploads/2023/07/11zon_cropped-25.png"
            className="solvedimg w-80 ml-10"
            alt="Image 2"
          />
          <div className="imgtext">
            <p className="solvedtext1 mt-4 text-gray-600 font-semibold text-xl">
              MATIAS BROCATO
            </p>
            <div className="">
              <p className="solvedtext text-gray-600 font-semibold text-sm">
                Chief Operating Officer
              </p>
            </div>
          </div>
        </div>
        <div className="columnthree text-center">
          <img
            src="https://gradedplus.app/wp-content/uploads/2023/07/MARCY-SUTHERLAND.jpg"
            className="solvedimg w-80 ml-10"
            alt="Image 2"
          />
          <div className="imgtext">
            <p className="solvedtext1 mt-4 text-gray-600 font-semibold text-xl">
              JULIO PENAGOS
            </p>
            <div className="">
              <p className="solvedtext text-gray-600 font-semibold text-sm">
                Founding Member & Director of Professional Learning
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row rowdo bg-blue-900">
        <div className="col-span-6 md:col-span-3 whydo">
          <div>
            <h2 className="headingtwo border-b-2 border-yellow-500 pb-2 text-gray-300 font-semibold text-2xl">
              Why do we do that?
            </h2>
          </div>
          <div className="paracontainer text-gray-300 font-normal text-lg mt-3">
            <p className="linemargin">
              SOLVED was founded and is led by former NYC Public School
              educators.
              <br />
              In collaboration with school leadership teams, SOLVED consultants
              support teachers’ efforts to improve students’ learning
              opportunities. We leverage six major areas of instructional design
              and implementation to improve teachers’ pedagogy and students’
              learning outcomes.
            </p>
            <p className="linemargin font-semibold">
              <strong>Promote Growth Mindset for Students & Teachers</strong>
            </p>
            <ul className="list-disc pl-6">
              <li>
                A school culture which values growth mindset encourages students
                and adults to persevere, learn from their mistakes, and take
                ownership of their own learning
              </li>
              <li>
                As students and adults learn how their brain becomes stronger
                through persistence and effort, they start to develop an
                attitude more conducive for intellectual growth.
              </li>
            </ul>
            <p className="linemargin font-semibold">
              <strong>Demonstrate Effective Feedback Strategies</strong>
            </p>
            <ul className="list-disc pl-6">
              <li>
                A school culture which values growth mindset encourages students
                and adults to persevere, learn from their mistakes, and take
                ownership of their own learning
              </li>
              <li>
                As students and adults learn how their brain becomes stronger
                through persistence and effort, they start to develop an
                attitude more conducive for intellectual growth.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
