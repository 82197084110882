import axios from 'axios';

// Fetch Post an Social API
export const fetchPostSocial = (schoolId, offset = 0, limit = 20) => {
  console.log('offset::::', offset);
  return axios.get(
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=a446ce01-bd2e-4272-b4a4-000985103020&offset=0&limit=12`,
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getSocialRSSFeed/${schoolId}?offset=0&limit=1000`,
    `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/news-and-social-feed?isNewAPI=true&filter=social&limit=${limit}&offset=${offset}&schoolId=${schoolId}`,
    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};


// Logo Fetch API
export const fetchlogoSocial = (schoolId) => {
  return axios.get(
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=a446ce01-bd2e-4272-b4a4-000985103020&offset=0&limit=12`,
    `https://ppwovzroa2.execute-api.us-east-2.amazonaws.com/production/getRecords/School_App__c/${schoolId}`,

    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};

// Fetch Interaction API
export const fetchInteractionSocial = (schoolId) => {
  return axios.get(
    // "https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/getUserAction?schoolId=a1f4W000007DR8nQAG&objectName=News",
    `https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/getUserAction?schoolId=${schoolId}&objectName=Social`,
    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};

export const sameSocial = (data, schoolId) => {
  return axios.post(
    `https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/addUserAction?schoolId=${schoolId}&objectName=Social&withTimeStamp=false`,
    data
  );
}

//  ------------- Neeeeeeeeeeewwwwwwwwwwwwsssssssssssss-------------------

// Fetch Post an News API
export const fetchPost = (schoolId, offset = 0, limit = 20) => {
  return axios.get(
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=a446ce01-bd2e-4272-b4a4-000985103020&offset=0&limit=12`,
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=${appId}&offset=${pageNumber}&limit=12`,
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?schoolId=${schoolId}&offset=0&limit=20`,
    `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/news-and-social-feed?isNewAPI=true&filter=news&limit=${limit}&offset=${offset}&schoolId=${schoolId}`,

    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};
// Logo Fetch API
export const fetchlogo = (schoolId) => {
  return axios.get(
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=a446ce01-bd2e-4272-b4a4-000985103020&offset=0&limit=12`,
    `https://ppwovzroa2.execute-api.us-east-2.amazonaws.com/production/getRecords/School_App__c/${schoolId}`,

    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};

// Fetch Interaction API
export const fetchInteraction = (schoolId) => {
  return axios.get(
    // "https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/getUserAction?schoolId=a1f4W000007DR8nQAG&objectName=News",
    `https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/getUserAction?schoolId=${schoolId}&objectName=News`,
    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};

export const same = (data, schoolId) => {
  return axios.post(
    // "https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/addUserAction?schoolId=a1f4W000007DR8nQAG&objectName=News",
    `https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/addUserAction?schoolId=${schoolId}&objectName=News`,
    data
  );
}


// ---------------------------Privacy Policy ---------------------
export const fetchPrivacyPolicy = () => {
  return axios.get(
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=a446ce01-bd2e-4272-b4a4-000985103020&offset=0&limit=12`,
    `https://ppwovzroa2.execute-api.us-east-2.amazonaws.com/production/getRecords/Privacy_Policy__c`,
    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};




// 
export const fetchdashboardlogo = (schoolId) => {
  return axios.get(
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=a446ce01-bd2e-4272-b4a4-000985103020&offset=0&limit=12`,
    `https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/getRecord/School_App__c/${schoolId}`,
    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};

// ICON Name
export const AppIcon = (schoolId) => {
  return axios.get(
    // `https://anl2h22jc4.execute-api.us-east-2.amazonaws.com/production/getNotifications?appId=a446ce01-bd2e-4272-b4a4-000985103020&offset=0&limit=12`,
    `https://ny67869sad.execute-api.us-east-2.amazonaws.com/production/filterRecords/iOS_and_Android_App_Details__c/"School_App__c"='${schoolId}'`,
    {
      headers: {
        Authorization: `Bearer Basic N2RmZjNlMzAtMDY4MS00MGI1LTg2ODgtNjY1YmU5NmJmZjlj`,
      },
    }
  );
};
