import React, { useEffect, useState} from "react";
import "./App.css";
import {fetchPrivacyPolicy,fetchlogoSocial} from "./apiFolder/index";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
export default function App() { 
  const [privacy, setPrivacy] = useState([]);
  const [logo, setLogo] = useState([]);
  const [loading, setLoading] = useState(true);

  const { 0: school_id } = useLocation().pathname.split("/").slice(-1);

  useEffect(() => {
    fetchPrivacyPolicy()
      .then((res) => {
        console.log("privacy", res.data.body);
        setPrivacy(res.data.body);
        setLoading(false);
      })
      .catch((error) => {
      });
      fetchlogoSocial(school_id)
      .then((res) => {
        console.log("logo00", res.data.body);
        setLogo(res.data.body);
      })
      .catch((error) => {
      });

}, []);

    return (
        <div>
          {privacy &&
            privacy.map((policy, index) => {
              // Replace the word "Hillside Arts" with the value of logo.Name
              let description = policy.Description__c.replace(
                 /\[APP_NAME\]/gi, 
                logo.Contact_Name__c
              );
      
              return (
                <div key={index} className="container  px-56 m-auto shan">
                  <div className="flex justify-center items-center text-6xl mb-16">{logo.Contact_Name__c}</div>
                  <div>{parse(description)}</div>
                </div>
              );
            })}
        </div>
      );
      

}