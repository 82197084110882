import React, { useState, useEffect, useRef } from "react";
import {
  fetchdashboardlogo,AppIcon
} from "./apiFolder/index";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const [logo, setLogo] = useState([]);
  const [icon, seticon] = useState([]);
  const { 0: school_id } = useLocation().pathname.split("/").slice(-1);
    useEffect(() => {
         // Fetch Logo
    fetchdashboardlogo(school_id)
    .then((res) => {
      console.log("logo00", res.data.body);
      setLogo(res.data.body);
    })
    .catch((error) => {
      console.log(error);
    });
      // Fetch Logo
      AppIcon(school_id)
      .then((res) => {
        console.log("ICON", res.data.body);
        seticon(res.data.body);
      })
      .catch((error) => {
        console.log(error);
      });

  //  -------
    
    }, []);
  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Navbar
       */}
      <div className="flex">
        <div className="w-1/2 p-4">
          <div className="text-center">
            <a href={`/home/${school_id}`}>
              <img
                src={logo.App_Logo__c}
                alt=""
                loading="lazy"
                className="w-20 mx-auto homelogo"
              />
            </a>
          </div>
          <div className="w-3/6">
            {icon.length > 0 && (
              <p className="text-center iconcss">{icon[0].App_Icon_Name__c}</p>
            )}
          </div>
        </div>
        <div className="w-1/2 p-4 bg-classic homelist">
          <nav className="flex items-center justify-center">
            <ul className="flex space-x-4">
              <li>
                <a href={`/home/${school_id}`} className="elementor-item">
                  Home
                </a>
              </li>
              <li>
                <a
                  href={`/About/${school_id}`}
                  className="elementor-item "
                >
                  About Us
                </a>
              </li>
              {/* <li>
                <a href="" className="elementor-item">
                  Blog
                </a>
              </li> */}
              <li>
                <a href={`/contact-us/${school_id}`} className="elementor-item elementor-item-active">
                  Contact Us
                </a>
              </li>
              <li>
                  <a href={`/privacy-policy/${school_id}`} className="elementor-item ">
                   Privacy Policy
                  </a>
                </li>
              {/* <li>
                <a href="" className="elementor-item elementor-download">
                  Download Now
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
      {/* Navbar end */}
      <div className="imagerow1 bg-cover">
        <div className="flex items-center justify-center conta">
          <h2 className="headingtwoo">
            <span className="spann">Contact </span>Us
          </h2>
        </div>
      </div>
      <div className="pararow flex items-center justify-center">
        <div className="paradiv bg-cover p-6">
          <p className="parasolved text-gray-600 text-center font-serif text-lg">
            The SOLVED founders, executive and supporting teams are made up of
            educators. Unlike most Silicon Valley tech companies, we are
            committed to building products that elevate your school rather than
            our brand.
          </p>
        </div>
      </div>
      {/* ****************** Contact page *************** */}
      <div className="formdiv">
        <p className="formpara">
          {" "}
          FILL OUT THIS FORM AND WE'LL GET BACK TO YOU VIA EMAIL .
        </p>
      </div>
      <div className="flex justify-center items-center  ">
        <div className="bg-white p-8 rounded shadow-md w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
          <h1 className="text-2xl font-semibold mb-4">Contact Us</h1>
          <form>
            <div className="flex mb-4">
              <div className="w-1/2 mr-2">
                <label
                  className="block text-gray-700 text-sm font-medium mb-1"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="w-1/2 ml-2">
                <label
                  className="block text-gray-700 text-sm font-medium mb-1"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <input
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-medium mb-1"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="email"
                id="email"
                name="email"
                placeholder="you@example.com"
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-medium mb-1"
                htmlFor="department"
              >
                Department
              </label>
              <select
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                id="department"
                name="department"
                required
              >
                <option value="" disabled>
                  Select a Department
                </option>
                <option value="webDesign">Web Design</option>
                <option value="webDevelopment">Web Development</option>
                <option value="wordpressDevelopment">
                  Wordpress Development
                </option>
                <option value="devOps">DevOps</option>
              </select>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-medium mb-1"
                htmlFor="subject"
              >
                Subject
              </label>
              <input
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-medium mb-1"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                id="description"
                name="description"
                rows="4"
                placeholder="Your message"
                required
              ></textarea>
            </div>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="formdiv">
        <p className="formpara"> WE LOOK FORWARD TO PARTNERING WITH YOU!.</p>
      </div>
    </div>
  );
};

export default Contact;
