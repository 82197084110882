import React, { useEffect, useState } from "react";
import "./App.css";
import { fetchdashboardlogo, AppIcon } from "./apiFolder/index";
import { useLocation } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import mobileAppBg from "./mobile_app_bg.png";

export default function App() {
  const [logo, setLogo] = useState([]);
  const [icon, seticon] = useState([]);
  const { 0: school_id } = useLocation().pathname.split("/").slice(-1);
  useEffect(() => {
    // Fetch Logo
    fetchdashboardlogo(school_id)
      .then((res) => {
        console.log("logo00", res.data.body);
        setLogo(res.data.body);
      })
      .catch((error) => {
        console.log(error);
      });

    // APP Icon
    // Fetch Logo
    AppIcon(school_id)
      .then((res) => {
        console.log("ICON", res.data.body);
        seticon(res.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    // Your Home page content
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="bg-white">
        <div className="container-fluid mx-auto">
          {/* navbar  */}
          <div className="flex navsetforhome">
            <div className="w-1/2 p-4">
              <div className="text-center">
                <a href={`/home/${school_id}`}>
                  <img
                    src={logo.App_Logo__c}
                    alt=""
                    loading="lazy"
                    className="w-20 mx-auto homelogo"
                  />
                </a>
              </div>
              <div className="w-3/6">
  {icon.length > 0 && (
    <p className="text-center iconcss">{icon[0].App_Icon_Name__c}</p>
  )}
</div>
            </div>
            <div className="w-1/2 p-4 bg-classic homelist">
              <nav className="flex items-center justify-center">
                <ul className="flex space-x-4">
                  <li>
                    <a
                      href={`/home/${school_id}`}
                      className="elementor-item elementor-item-active"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a href={`/About/${school_id}`} className="elementor-item ">
                      About Us
                    </a>
                  </li>
                  {/* <li>
                  <a href="" className="elementor-item">
                    Blog
                  </a>
                </li> */}
                  <li>
                    <a
                      href={`/contact-us/${school_id}`}
                      className="elementor-item"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href={`/privacy-policy/${school_id}`}
                      className="elementor-item "
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {/* navbar */}

          <div className="flex flex-wrap">
            <div className="w-7/12 wholedivseven">
              <div className="mt-20 ml-4">
                <p className="mb-4">Tired of multiple choice assessments?</p>
              </div>
              <div className="mt-12 ml-4">
                <h1 className="text-4xl font-bold mb-4">
                  STANDARDIZED TESTING IS SUCKING THE OXYGEN OUT OF THE ROOM!
                </h1>
              </div>
              <div className="mt-10 ml-4">
                <p className="mb-10">
                  GRADED+ promotes and encourages problem solving (MATH) and
                  short response writing (ELA) in contrast to overly used
                  multiple choice tests. Our goal is to center the conversation
                  on student work using frequent, teacher-assigned formative
                  assessments.
                </p>
                <p className="mb-4">
                  GRADED+ automatically feeds results to your SOLVED Data
                  Dashboard. We believe that easy access to data empowers
                  educators to better tailor classroom instruction and drive
                  student achievement. Rather than digging through multiple
                  Dropbox and Google Drive folders, Excel tabs, and emails, the
                  SOLVED Data Dashboard houses all of your students' assessment
                  data in a single, simple-to-use tool.
                </p>
              </div>
              {/* <div className="body-btn-contaner">
              <button
                type="button"
                className="hover:bg-blue-700 text-white font-bold py-2 ml-4 px-4 rounded-md backgroundbutton"
              >
                <a
                  href="https://datastudio.google.com/u/0/reporting/75743c2d-5749-45e7-9562-58d0928662b2/page/kzcgB"
                  className="text-white hover:text-white"
                >
                  See Example{" "}
                </a>
              </button>
            </div> */}
            </div>

            <div class="w-5/12 bg-mobile-side-image bg-no-repeat bg-right h-screen">
              <img
                src={mobileAppBg}
                alt="img"
                className="mt-28 imageofmobile"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
