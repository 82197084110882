// import React , {useEffect} from 'react';
// // import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';

// import News from './news';
// import Social from './social';
// import PrivacyPolicy from './privacy-policy';

// function App() {
//   function AppTitle() {
//     const location = useLocation();

//     useEffect(() => {
//       let title = '';

//       switch (location.pathname) {
//         case '/news':
//           title = 'News';
//           break;
//         case '/social':
//           title = 'Social';
//           break;
//         case './privacy-policy':
//           title = 'Privacy Policy';
//           break;
//         default:
//           title = 'My App';
//       }

//       document.title = title;
//     }, [location]);

//     // return null;
//   }

//   return (
//     <>
//     <Router>
//     <AppTitle />
//       <Switch>
//         <Route path="/news">
//           <News />
//         </Route>
//         <Route path="/social">
//           <Social />
//         </Route>
//         <Route path="/privacy-policy">
//           <PrivacyPolicy />
//         </Route>
//       </Switch>
//     </Router>
//     </>
//   );
// }

// export default App;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import News from "./news";
import Social from "./social";
import PrivacyPolicy from "./privacy-policy";
import Home from "./home";
import About from "./about"
import ContactUs from "./contact_us"
import LatestNews from "./latest-news";
import LatestSocial from "./latest-social";

function App() {
  function AppTitle() {
    const location = useLocation();

    useEffect(() => {
      const pathSegments = location.pathname.split("/");
      let title = "";

      switch (pathSegments[1].toLowerCase()) {
        case "news":
          title = "News";
          break;
        case "latest-news":
          title = "Latest";
          break;
        case "latest-social":
          title = "Latest";
          break;
        case "social":
          title = "Social";
          break;
        case "privacy-policy":
          title = "Privacy Policy";
          break;
        case "home":
          title = "Home";
          break;
        case "about":
          title = "About";
          break;
        case "contact_us":
          title = "Contact-us";
          break;
        default:
          title = "SOLVED";
      }

      if (pathSegments.length > 2 && !pathSegments[2].startsWith("a1f4")) {
        title += ` ${pathSegments[2]}`;
      }

      document.title = title;
    }, [location]);

    return null;
  }

  return (
    <>
      <Router>
        <AppTitle />
        <Switch>
          <Route path="/news/:id/latest">
            <LatestNews />
          </Route>
          <Route path="/news/:id">
            <News />
          </Route>
          <Route path="/social/:id/latest">
            <LatestSocial />
          </Route>
          <Route path="/social/:id">
            <Social />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/home/:id">
            <Home />
          </Route>
          <Route path="/about/:id">
            <About />
          </Route>
          <Route path="/contact-us/:id">
            <ContactUs />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
